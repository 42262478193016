<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent' : ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="primary" size="sm" @click="checkForBusinessAccount">
            {{ btn }}
          </base-button>
        </div>
      </div>
    </div>

    <confirmation-modal v-if="modals.showConfirmationModal"
      :message="`Are you sure you want to delete '${currentItem.name}' export?`" @confirm="handleDeleteConfirm"
      @cancel="modals.showConfirmationModal = false"></confirmation-modal>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush table-integration"
        :class="type === 'dark' ? 'table-dark' : ''" :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list" :data="filteredExports">
        <template v-slot:columns>
          <th>Service</th>
          <th>Label</th>
          <th>Status</th>
          <th>Off/On</th>
          <th></th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <div class="media align-items-center">
              <!--              <a href="#" class="avatar rounded-circle mr-3">-->
              <!--                <img alt="Image placeholder" :src="row.item.img" />-->
              <!--              </a>-->
              <div class="media-body">
                <span class="name mb-0 text-sm">{{
                  this.services.find(item => item.id === row.item.receiving_service_id).name
                }}</span>
              </div>
            </div>
          </th>
          <th scope="row">
            <div class="media align-items-center">
              <!--              <a href="#" class="avatar rounded-circle mr-3">-->
              <!--                <img alt="Image placeholder" :src="row.item.img" />-->
              <!--              </a>-->
              <div class="media-body">
                <span class="name mb-0 text-sm">{{
                  row.item.name
                }}</span>
              </div>
            </div>
          </th>
          <td class="account">
            {{ row.item.is_active ? "Active" : "Not active" }}
          </td>
          <td class="on-off">
            <base-switch :value="returnExportStatus(row.item.is_active)" class="ml-1"
              @input="changeExportStatus($event, row.item)"></base-switch>
          </td>
          <td class="settings text-right table-integrations_button">
            <router-link :to="{
              name: 'export_settings',
              params: {
                service_path: getServicePath(row.item.receiving_service_id),
                id_export: row.item.id,
              },
            }">
              <base-button class="button-settings" type="warning" size="sm"> Settings </base-button>
            </router-link>
            <base-button class="button-delete" type="default" size="sm" :disabled="isExportDeleteLoading(row.item)"
              @click="prepareDeletion(row.item)">
              Delete
            </base-button>
            <div v-show="isExportDeleteLoading(row.item)" class="loading_row">
              <img src="/img/Reload-1s-200px1.svg" alt="">
            </div>
          </td>
        </template>
      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent' : ''">
      <!--      <base-pagination total="30"></base-pagination>-->
    </div>
  </div>
  <modal v-model:show="modals.addExport" modalClasses="modal-add-integrations">
    <template v-slot:header>
      <h3 class="modal-title" id="modal-title-default">Add export</h3>
    </template>

    <div class="flex flex-wrap">
      <table>
        <tr style="height:50px">
          <th style="width:200px">Export</th>
          <th style="width:350px">Info</th>
          <th style="width:200px"></th>
        </tr>
        <tr style="height:50px" v-for="service in exportServices" :key="service.id">
          <td>{{ service.name }}</td>
          <td style="height:150px">{{ service.info }}</td>
          <td style="padding: 0px 0px 0px 80px">
            <base-button type="primary" size="sm" :block="true" @click="addExport(service)">Add</base-button>
          </td>
        </tr>
      </table>
      <p v-if="Object.keys(services).length == 0">No services available</p>
    </div>

    <template v-slot:footer>
      <base-button type="link" class="ml-auto" @click="modals.addExport = false">Close
      </base-button>
    </template>
  </modal>

  <modal v-model:show="modals.addAuthData" modalClasses="modal-add-integrations">
    <template v-slot:header>
      <h3 class="modal-title" id="modal-title-default">Add Authorization Data</h3>
    </template>

    <template v-slot:default>
      <form class="auth-form"
        v-if="current_service && current_service.auth_template && current_service.auth_template.field_config"
        @submit.prevent="addAuthData">

        <div class="form-group" v-for="(config, key) in current_service.auth_template.field_config" :key="key">
          <label :for="key" :class="{ 'checkbox-label': config.type === 'boolean' }">{{ config.title }}</label>
          <input v-if="config.type !== 'boolean'" :id="key" :type="getInputType(config.type)" v-model="formData[ key ]"
            :readonly="key === 'client_id'" required />
          <div v-else class="checkbox-group">
            <input type="checkbox" :id="key" v-model="formData[ key ]" />
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>

        <div v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>
      </form>
    </template>

    <template v-slot:footer>
      <base-button type="link" class="ml-auto" @click="modals.addAuthData = false">Close</base-button>
    </template>
  </modal>
</template>
<script>
import { mapGetters } from "vuex";
import apiService from "@/services/api";
import ConfirmationModal from "../Modals/ConfirmationModal.vue";

export default {
  name: "projects-table",
  props: {
    type: {
      type: String,
    },
    title: String,
    btn: String,
  },
  components: {
    ConfirmationModal
  },
  data() {
    return {
      exports: [],
      services: [],
      modals: {
        addExport: false,
        addAuthData: false,
        showConfirmationModal: false,
      },
      current_service: null,
      current_item: null,
      errorMessage: null
    };
  },
  computed: {
    ...mapGetters('user', [
      'getUser',
      'getCurrentBusinessAccount',
      'getVuexBusinessAccounts'
    ]),
    exportServices() {
      return this.services.filter(service => service.type === 'export');
    },
    filteredExports() {
      return this.exports.filter(exportItem =>
        exportItem.ba_id === this.getCurrentBusinessAccount.id && exportItem.service_type === "export"
      );
    },
  },
  created() {
    this.getBusinessAccounts(this.getUser.id);
    this.getServices();
    this.getIntegrationsExports();
  },
  methods: {
    addExport(service) {
      if (service.auth_template && typeof service.auth_template === 'string') {
        service.auth_template = JSON.parse(service.auth_template);
      }
      this.current_service = service;
      if (service.auth_template == null) {
        apiService
          .addExport(this.getUser.id, service)
          .then((response) => {
            let url_redirect = response.data.service_response.data;
            window.location.replace(url_redirect);
          });
      }
      else {
        this.modals.addExport = false;
        this.modals.addAuthData = true;
      }
    },
    addAuthData() {
      this.formData.created_by = this.getUser.id;
      this.formData.ba_id = this.getCurrentBusinessAccount.id;
      this.formData.receiving_service_id = this.current_service.id
      this.formData.receiving_service_status = this.current_service.is_active;
      this.formData.service_type = this.current_service.type;
      this.formData.is_active = false;
      this.formData.status = 'disabled';

      if (!this.formData.domain) {
        this.formData.domain = this.current_service.path;
      }

      apiService.addAuthData(this.formData)
        .then((response) => {
          if (!response.data.success) {
            // Handle the case where success is false. Keep the modal open, only show the error message
            this.errorMessage = response.data.message;
          } else {
            this.modals.addAuthData = false;
            this.getIntegrationsExports();
          }
        })
        .catch(error => {
          console.error("Error:", error);
        });
    },
    prepareDeletion(item) {
      this.currentItem = item;
      this.modals.showConfirmationModal = true;
    },
    handleDeleteConfirm() {
      if (this.currentItem.api_key === null && this.currentItem.access_token !== null) {
        this.deleteExportOAuth(this.currentItem.receiving_service_id, this.currentItem.access_token);
      } else {
        this.deleteExport(this.currentItem.receiving_service_id, this.currentItem.api_key);
      }
    },
    deleteExport() {
      apiService
        .deleteExport(this.currentItem.receiving_service_token)
        .then((response) => {
          console.log(response);
          this.getIntegrationsExports();
          this.modals.showConfirmationModal = false;
        });
    },
    deleteExportOAuth() {
      apiService
        .deleteExportOAuth(this.currentItem.service_id, this.currentItem.access_token)
        .then((response) => {
          console.log(response);
          this.getIntegrationsExports();
          this.modals.showConfirmationModal = false;
        });
    },
    isExportDeleteLoading(export_item) {
      return (export_item.delete_loading !== undefined && export_item.delete_loading == true) ? true : false;
    },
    getServices() {
      let type = 'export';
      apiService
        .getServices(this.getUser.id, type)
        .then((response) => {
          this.services = response.data.data.services;
          console.log('Services: ', this.services);
        });
    },
    returnExportStatus(export_status) {
      return (export_status == 1) ? true : false
    },
    changeExportStatus($event, export_item) {
      apiService
        .updateStatusExport({
          'created_by': this.getUser.id,
          'export_id': export_item.id,
          'status': $event,
        })
        .then((response) => {
          if (response.data.success == true) {
            export_item.is_active = Boolean($event);
          }
        });
    },
    getIntegrationsExports() {
      apiService
        .getIntegrationsExports(this.getUser.id)
        .then((response) => {
          this.exports = response.data;
          console.log("Exports: ", this.exports)
        });
    },
    checkForBusinessAccount() {
      if (this.getVuexBusinessAccounts.length > 0) {
        this.modals.addExport = true;
      } else {
        this.$router.push({ name: "business_accounts" });
      }
    },
    getBusinessAccounts(userId) {
      return apiService
        .getBusinessAccountsByUserId(userId)
        .then((response) => {
          this.$store.commit('user/setBusinessAccounts', response.data.data); // Committing to the store
          return response;
        });
    },
    getServicePath(serviceId) {
      const service = this.services.find(service => service.id === serviceId);
      return service ? service.service_path : 'Path not found';
    },
    getInputType(typeString) {
      return typeString.includes('string') ? 'text' : 'text';
    },
  },
  watch: {
    'current_service.auth_template': {
      deep: true,
      handler() {
        if (this.current_service && this.current_service.auth_template && this.current_service.auth_template.field_config) {
          this.formData = Object.keys(this.current_service.auth_template.field_config).reduce((acc, key) => {
            // Predefine the client_id field with this.getUser.id if it exists
            if (key === 'client_id' && this.user && this.getUser.id) {
              acc[ key ] = this.getUser.id;
            } else {
              acc[ key ] = '';
            }
            return acc;
          }, {});
        }
      }
    },
  }
};
</script>
<style>
.auth-form {
  max-width: 30em;
  margin: auto;
}

.auth-form .form-group {
  margin-bottom: 1rem;
}

.auth-form label {
  display: block;
  margin-bottom: .5rem;
  font-weight: bold;
}

.auth-form input[type="text"],
.auth-form input[type="checkbox"] {
  width: 100%;
  padding: .5rem;
  margin-bottom: .5rem;
  border: 1px solid #ccc;
  border-radius: .25rem;
}

.auth-form input[type="checkbox"] {
  width: auto;
}

.auth-form input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.auth-form button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: .5rem 1rem;
  border: none;
  border-radius: .25rem;
  cursor: pointer;
}

.auth-form button[type="submit"]:hover {
  background-color: #0056b3;
}

.auth-form .form-check {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.auth-form .form-check-label {
  margin-left: .5rem;
  font-weight: normal;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}
</style>
